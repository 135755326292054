<template>
  <section class="landing-steps">
    <div class="container text-info">
      <span class="above-title">{{ $t('whereStart') }}</span>
      <h2 class="title">
        {{ $t('easy3Steps') }}
        <span>{{ $t('visualizeIdeas') }}</span>
      </h2>
      <p class="text">
        {{ $t('needWindowsPC') }}
      </p>
    </div>
    <div class="cards">
      <div class="item">
        <IconStep1 />
        <div class="title">
          {{ $t('downloadTheApp') }}
        </div>
        <p class="text">
          {{ $t('downloadInstallation') }}
          <br />
          {{ $t('coupleMinutes') }}
          <span
            >{{ $t('system') }}
            <br />
            {{ $t('system2') }}.</span
          >
          <ui-link
            class="to-system-link"
            to="help-center/about-dream-flat?article=installingRunning"
          >
            {{ $t('seeMore') }}
          </ui-link>
          <!--help-center/-->
        </p>
        <div class="item-btn steps-download-button">
          <DownloadLauncherButton
            :label="$t('downloadApp')"
            :add-version-to-label="false"
          />
        </div>
      </div>
      <div class="item">
        <IconStep2 />
        <div class="title">
          {{ $t('createLayout') }}
        </div>
        <p class="text">
          {{ $t('uploadLayout') }}
          <br />
          {{ $t('orEditor') }}
        </p>
        <div class="item-btn">
          <ui-link
            to="/help-center"
            class="link steps-link"
            color="primary"
          >
            {{ $t('exploreManual') }}
          </ui-link>
        </div>
      </div>
      <div class="item">
        <IconStep3 />
        <div class="title">
          {{ $t('enjoyMagic') }}
        </div>
        <p class="text">
          {{ $t('dreamFlatOpportunity') }}
          <br />
          {{ $t('createDesignPleasure') }}
        </p>
        <div class="item-btn">
          <ui-link
            to="/gallery"
            class="link steps-link"
            color="primary"
          >
            {{ $t('exploreGallery') }}
          </ui-link>
        </div>
      </div>
      <img
        :src="`${staticUrl}/images/mp-vector1.webp`"
        class="cards-bg item-r"
        loading="lazy"
        alt=""
      />
      <img
        :src="`${staticUrl}/images/mp-vector1.webp`"
        class="cards-bg item-l"
        loading="lazy"
        alt=""
      />
    </div>
  </section>
</template>

<script setup>
import DownloadLauncherButton from '~/components/DownloadLauncherButton.vue';
import IconStep1 from '~/assets/mainPage/Frame292.svg';
import IconStep2 from '~/assets/mainPage/Frame293.svg';
import IconStep3 from '~/assets/mainPage/Frame294.svg';
import {useRuntimeConfig} from '#imports';

const runtimeConfig = useRuntimeConfig();
const staticUrl = runtimeConfig.public.vueAppStaticUrl;
</script>
<style lang="scss" scoped>
.above-title {
  font-weight: 700;
  font-size: 18px;
  color: #64686e;
}
.steps-link::after {
  content: url($static-url + '/images/mp-arrow-right.svg') !important;
  margin-left: 10px;
}

.landing-steps {
  .item {
    svg {
      margin-bottom: 48px;
    }
  }
  .text {
    .to-system-link {
      font-size: 12px;
      color: $primary;
    }
  }
}

@media (max-width: 1024px) {
  .landing-steps {
    .text-info {
      margin-top: 156px !important;
      padding: 0 !important;
      width: 100%;
      .above-title {
        font-size: 18px;
      }
      .title {
        font-size: 24px;
        margin: 8px 0;
        padding: 0;
        width: 100%;
      }
      .text {
        font-size: 16px;
      }
    }
    .cards {
      margin-top: 48px;
      .item {
        width: 100%;
        svg {
          margin-bottom: 16px;
          width: 48px;
          height: 48px;
        }
        .title {
          font-size: 18px;
        }
        .text {
          font-size: 15px;
          margin: 0;
        }
        .link {
          font-size: 16px;
        }
      }
    }
  }
  .steps-download-button {
    //margin-top: 8px;
    margin-top: 40px;
  }
}
@media (max-width: 600px) {
  .steps-download-button {
    display: none !important;
  }
}
</style>

<template>
  <section class="landing-questions">
    <div class="container container--section-list">
      <div class="section-list">
        <h3 class="title">
          {{ $t('answers') }}
        </h3>
        <p class="text">
          {{ $t('stillQuestions') }}
          <!-- <ui-link
            to="/help-center"
            class="link"
          >
            {{ $t('contactUs') }}
          </ui-link> -->
          <span class="link" @click="isShowFeedBackModal = true">
            {{ $t('contactUs') }}
          </span>
        </p>
        <div class="list__wrp">
          <div class="list__item">
            <ui-accordion>
              <template #trigger>
                {{ $t('whatGetThis') }}
              </template>
              <template #content>
                <p class="list__text">
                  {{ $t('interiorFuture') }}
                  {{ $t('helpSaveMoney') }}
                </p>
              </template>
            </ui-accordion>
          </div>
          <div class="list__item">
            <ui-accordion>
              <template #trigger>
                {{ $t('appFree') }}
              </template>
              <template #content>
                <p class="list__text">
                  {{ $t('downloadUse') }}
                </p>
              </template>
            </ui-accordion>
          </div>
          <div class="list__item">
            <ui-accordion>
              <template #trigger>
                {{ $t('platformsDoes') }}
              </template>
              <template #content>
                <p class="list__text">
                  {{ $t('windowsVer') }}
                </p>
              </template>
            </ui-accordion>
          </div>
          <div class="list__item">
            <ui-accordion>
              <template #trigger>
                {{ $t('howUseApp') }}
              </template>
              <template #content>
                <p class="list__text">
                  {{ $t('intuitiveNavigation') }}
                </p>
              </template>
            </ui-accordion>
          </div>
        </div>
      </div>
    </div>
    <lazy-feed-back-modal
      v-if="isShowFeedBackModal"
      :show="isShowFeedBackModal"
      @update:show="isShowFeedBackModal = !isShowFeedBackModal"
    />
  </section>
</template>

<script>
import UiAccordion from '~/components/Ui/UiAccordion';
export default {
  name: 'LandingQuestions',
  components: {UiAccordion},
  setup() {
    const isShowFeedBackModal = ref(false);

    return {
      isShowFeedBackModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.section-list {
  position: relative;
  margin-bottom: 256px;
  background: #f6f8fd;
  border-radius: 32px;
  padding: 32px 32px 16px;

  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    margin-top: 0;
    margin-bottom: 24px;
    letter-spacing: 0.03em;
    color: #3d424a;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #64686e;

    .link {
      color: #3e9eea;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .list__wrp {
    //max-width: 744px;
    //margin: 40px auto 0;
    margin: 48px 0 0;

    .list__item {
      align-items: center;
      border: 2px solid #e1e5f0;
      box-sizing: border-box;
      border-radius: 16px;
      margin-bottom: 16px;
      background: #ffffff;

      .q-item {
        align-items: center;
      }

      .list__item-icon-main {
        padding-left: 0;
        align-items: center;
        width: 36px;
        height: 36px;
        color: #ffffff;
        background: $primary;
        margin: 12px !important;
        //box-shadow: 0 3px 10px rgba(62, 158, 234, 0.25);
        border-radius: 10px;
      }
    }

    .list__item-text {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #3d424a;
    }

    .list__text {
      background: #ffffff;
      //padding: 0 24px;
      padding: 0 64px 0 24px;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.03em;
      color: #839aaf;
    }
    .item {
      .trigger {
        .header {
          display: block;
        }
      }
    }
  }
}

body .q-expansion-item__container .q-hoverable:hover .q-focus-helper {
  background: inherit;
  opacity: 0;
}
</style>

<template>
  <section class="landing-hero">
    <div class="head">
      <div class="title">
        <div/>
        <h1 id="hero-head-title">
          <!--          {{ $t('Create your dream design in a few minutes') }}-->
          {{ $t('createDream') }}
        </h1>
        <div class="sub-title">
          <!--          {{ $t('DreamFlat - easy to use interior design app') }}-->
          {{ $t('easyInterior') }}
        </div>
        <div class="goahead">
          <div class="goahead-item">
            <DownloadLauncherButton
              icon="img:windows-icon.svg"
              :add-version-to-label="false"
              :isGoToTariff="true"
            >
              <IconWindows/>
            </DownloadLauncherButton>
            <ui-button
              :color="'white'"
              class="goahead-btn"
              :to="`/${locale}/design-studio`"
            >
              {{ $t('orderDesign') }}
            </ui-button>
          </div>
        </div>
      </div>
      <div
        class="head-img-container"
        @click="
          $emit('showVideo', locale === 'ru' ? 'promo-ru' : 'promo')
        "
      >
        <video
          class="head-img"
          no-controls
          autoplay
          loop
          playsinline
          muted
          :poster="`${staticUrl}/images/intro_800x648.webp`"
        >
          <source
            :src="`${staticUrl}/video/intro_800x648.mp4`"
            type="video/mp4"
          />
          Video tag not supported
        </video>
      </div>
      <div class="mobile-background-radial-image-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1338"
          height="510"
          viewBox="0 0 428 437"
          fill="none"
        >
          <path
            d="M101.252 329.621C-39.3359 332.506 -228.214 414.085 -265 435L-124.299 -75L995.668 -61.3249L1073 294.763C1005.07 315.946 881.55 395.047 648.75 376.009C494.023 363.356 218.86 327.208 101.252 329.621Z"
            fill="#F6F7FA"
            stroke="#F4F6FD"
          />
        </svg>
      </div>
    </div>
    <div>
      <div class="cards-head">
        <div class="item custom-width">
          <h3 class="title">
            {{ $t('thousands') }}
          </h3>
          <p class="text">
            {{ $t('Real3DModels') }}
          </p>
        </div>
        <div class="item custom-width">
          <h3 class="title">
            {{ $t('seconds90') }}
          </h3>
          <p class="text">
            {{ $t('takesStart') }}
          </p>
        </div>
        <div class="item">
          <h3 class="title">Unreal Engine 5</h3>
          <p class="text">
            {{ $t('forPhotorealistic') }}
          </p>
        </div>
      </div>
    </div>
    <div class="m-btn">
      <ui-button
        style="margin: 0; border: none"
        color="primary"
        class="how-to-btn"
        :to="`/${locale}/design-studio`"
      >
        {{ $t('orderDesign') }}
      </ui-button>
    </div>
  </section>
</template>

<script setup lang="ts">
import DownloadLauncherButton from '~/components/DownloadLauncherButton.vue';
import IconWindows from '~/assets/svg/windows-icon.svg';
import UiButton from '~/components/Ui/UiButton';
import {i18n} from '~/plugins/i18n';
import {useRuntimeConfig} from '#imports';

const runtimeConfig = useRuntimeConfig();
const staticUrl = runtimeConfig.public.vueAppStaticUrl;
const isDflat =
  runtimeConfig.public.vueAppBaseUrl.includes('d-flat.ru');
defineEmits(['showVideo']);

const locale = (i18n.global.locale as any).value;
</script>

<style scoped lang="scss">
.goahead-item {
  display: flex;
  align-items: center;

  .goahead-btn {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $primary;
    cursor: pointer;
  }
}

.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 156px;

  .title {
    h1 {
      max-width: 460px;
      font-weight: 700;
      font-size: 36px;
      line-height: 120%;
      letter-spacing: 0.03em;
      color: $dark;
    }

    #hero-head-title {
      margin: 24px 0 !important;
    }

    .sub-title {
      font-size: 16px;
      color: #64686e;
      max-width: 460px;
    }

    .goahead {
      max-width: 460px;
      margin: 60px 0 74px 0;
      display: flex;
    }

    .goahead-btn {
      margin-left: 16px;
      margin-right: 16px;
      max-height: 40px;
      text-align: center;
      line-height: 1.15;
    }
  }

  .head-img-container {
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.5));
    width: 400px;
    height: 324px;
    cursor: pointer;

    .head-img {
      height: 324px;
      background-size: contain;
      position: relative;
      // left: -90px;
      mask-image: url(assets/head-image-mask.svg);
      mask-size: contain;
      mask-repeat: no-repeat;
      // mask-position: 90px;
    }
  }

  .mobile-background-radial-image-block {
    display: none;
  }
}

.cards-head {
  display: flex;
  justify-content: flex-start;

  .item {
    margin-right: 24px;
    max-width: 220px;
    //width: 168px;
  }

  .custom-width {
    width: 168px;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 4px;
    letter-spacing: 0.03em;
    color: #3d424a;

    span {
      color: $primary;
    }
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #3d424a;
  }
}

@media (max-width: 1024px) {
  .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 56px;

    .title {
      h1 {
        max-width: 460px;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: 0.03em;
        color: $dark;
      }

      #hero-head-title {
        //margin: 24px 0 !important;
        margin: 0 0 12px 0 !important;
      }

      .sub-title {
        font-size: 16px;
        color: #64686e;
        max-width: 460px;
      }

      .goahead {
        display: none;
        max-width: 460px;
        margin: 40px 0;
        //display: flex;
      }

      .goahead-btn {
        margin-left: 16px;
      }
    }

    .head-img-container {
      position: absolute;
      left: 0;
      z-index: -10;
      opacity: 25%;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      overflow: hidden;
      margin: 0 auto;
      filter: none;
      display: none;

      .head-img {
        background-size: contain;
        position: relative;
        -webkit-mask-image: none;
        mask-image: none;
        height: 89.4vh;
      }
    }
  }

  .cards-head {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 40px;

    .custom-width {
      width: 100%;
    }

    .item {
      margin-right: 0;
      margin-bottom: 12px;
      width: 100%;

      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 4px;
        letter-spacing: 0.03em;
        color: #3d424a;

        span {
          color: $primary;
        }
      }

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #3d424a;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
  .m-btn {
    .download-app-on-mobile {
      margin: 0 auto 16px auto;

      button {
        width: 100%;
      }
    }

    .how-to-btn {
      font-weight: 600;
      width: 100%;
    }
  }
}

@media (max-width: 900px) {
  .head {
    .head-img-container {
      //filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.5));
      //width: 400px;
      //height: 324px;
      //cursor: pointer;
      display: block !important;
      position: absolute;
      left: 0%;
      z-index: -10;
      opacity: 25%;
      width: 100%;
      height: 600px;
      box-sizing: border-box;
      overflow: hidden;
      margin: 0 auto;
      filter: none;

      .head-img {
        background-size: contain;
        position: relative;
        -webkit-mask-image: none;
        mask-image: none;
        height: 785px !important;
      }
    }

    .mobile-background-radial-image-block {
      display: block !important;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      left: 0;
      top: -2%;
      overflow: hidden;

      svg {
        width: 100%;
      }
    }
  }
}

@media (max-width: 460px) {
  .head {
    .head-img-container {
      //filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.5));
      //width: 400px;
      //height: 324px;
      //cursor: pointer;
      display: block !important;
      position: absolute;
      left: 0%;
      z-index: -10;
      opacity: 25%;
      height: 630px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      margin: 0 auto;
      filter: none;

      .head-img {
        background-size: contain;
        position: relative;
        -webkit-mask-image: none;
        mask-image: none;
      }
    }

    .mobile-background-radial-image-block {
      display: block !important;
      position: absolute;
      z-index: -1;
      width: 100%;
      left: 0;
      top: -2%;
      overflow: hidden;

      svg {
        width: 100%;
      }
    }
  }
}
</style>

<template>
  <section class="landing-gallery">
    <div class="info-section__2">
      <div class="container">
        <div class="title">
          {{ $t('getinspired') }}<br />
          {{ $t('share') }}
        </div>
        <p class="text">
          {{ $t('interiorModels1000') }}
          {{ $t('designEasy') }}
        </p>
        <ui-link to="/gallery">
          <ui-button color="primary" :label="$t('viewGallery')" />
        </ui-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LandingGallery',
};
</script>

<style scoped lang="scss">
.info-section__2 {
  background: no-repeat
    url($static-url + '/images/landing-gallery-background.webp');
  background-size: cover;
  padding: 96px 0 96px;
  position: relative;
  overflow: hidden;
  a {
    text-decoration: none;
    color: var(--var-white-100);
  }

  .img {
    position: absolute;
    background-position: center;
    left: 0px;
    bottom: 0px;
    height: 100%;
    width: 100%;
    z-index: -1;
    @media (max-width: 500px) {
      min-width: 120vw;
      min-height: 30vh;
    }
  }

  .title {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: var(--var-white-100);
  }

  .text {
    max-width: 450px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 32px;
    letter-spacing: 0.02em;
    color: var(--var-white-100);
  }
}
@media (max-width: 600px) {
  .info-section__2 .container {
    padding: 0;
  }
  .info-section__2 button {
    width: 100%;
  }
  .info-section__2 {
    .title {
      margin-bottom: 8px;
    }
    .text {
      margin-top: 8px;
      margin-bottom: 98px !important;
    }
  }
}
</style>

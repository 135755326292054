<template>
  <section class="landing-try">
    <div class="info-section">
      <img
        class="landing-try-image"
        :src="`${staticUrl}/images/mp-laptop.webp`"
        alt="Try it now."
        loading="lazy"
      />
      <div class="info try-info">
        <div class="title">
          {{ $t('reallySimple') }}
          <br />
          {{ $t('tryNow') }}
        </div>
        <p class="text">
          {{ $t('gamelikeControls') }}
          <br />
          {{ $t('applicatWorks') }}
          <br />
          {{ $t('dontHesitateContact') }}
        </p>
        <img
          class="m-img"
          :src="`${staticUrl}/images/mp-laptop.webp`"
          alt="Try it now."
          loading="lazy"
        />
        <div class="m-btn">
          <p class="m-btn-text">
            {{ $t('availableWindows') }}
          </p>
          <ui-button
            class="landing-try-m-btn"
            color="primary"
            icon="play_circle_outline"
            :label="$t('howToUse')"
            no-caps
            :to="{path: 'help-center'}"
          >
            <template #before>
              <iconPlayCircle />
            </template>
          </ui-button>
        </div>
        <div class="goahead">
          <div class="goahead-item">
            <div class="goahead-item-after">
              {{ $t('itsFree') }}
            </div>
            <DownloadLauncherButton
              :add-version-to-label="false"
            />
          </div>
          <ui-link
            to="/help-center"
            class="goahead-btn q-ml-lg"
            style="padding: 0 20px 0 17px"
            @click="$emit('showVideo', 'promo')"
          >
            <iconPlay />
            {{ $t('howToWork') }}
          </ui-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import IconPlayCircle from '~/assets/svg/play_circle.svg';
import UiButton from '~/components/Ui/UiButton';
import IconPlay from '~/assets/svg/play.svg';
import DownloadLauncherButton from '~/components/DownloadLauncherButton.vue';
import UiLink from '~/components/Ui/UiLink';
import {useRuntimeConfig} from '#imports';

const runtimeConfig = useRuntimeConfig();
const staticUrl = runtimeConfig.public.vueAppStaticUrl;

defineEmits(['showVideo']);
</script>

<style lang="scss" scoped>
.landing-try {
  .info-section {
    margin-bottom: 256px;
    gap: 64px;
  }
}
.goahead {
  display: flex;
  align-items: center;
  .goahead-item {
    width: 178px;
    button {
      width: 100%;
    }
  }
  .goahead-btn {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $primary;
    cursor: pointer;
  }
}
.m-img {
  width: 100%;
}
.landing-try-image {
  width: 500px;
}
@media (max-width: 1024px) {
  .m-btn {
    .landing-try-m-btn {
      width: 100% !important;
    }
  }
}
@media (max-width: 900px) {
  .m-btn {
    .landing-try-m-btn {
      width: 100% !important;
    }
  }
}
@media (max-width: 600px) {
  .m-img {
    display: block;
    max-width: 100% !important;
    margin: 0 auto;
  }
  .m-btn {
    .landing-try-m-btn {
      width: 100% !important;
    }
  }
}
</style>

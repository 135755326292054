<template>
  <section class="landing-banner">
    <div class="container">
      <div class="landing-banner-info">
        <div class="landing-banner-info__date">{{ $t('landing_tv-banner_date') }}</div>
        <div class="landing-banner-info__title">{{ $t('landing_tv-banner_title') }}</div>
        <div class="landing-banner-info__description">{{ $t('landing_tv-banner_desc') }}</div>
        <div class="landing-banner-info__promo">{{ $t('landing_tv-banner_promo') }}</div>
        <UiButton
          class="landing-banner__btn"
          color="white"
          label="Выбрать тариф"
          @click="scrollToTariff"
        />
      </div>
      <div class="landing-banner-image-container">
        <div class="landing-banner-image">
          <picture>
            <source
              :srcset="`${staticUrl}/images/banner-tv-photo.webp`"
              type="image/webp"
            />
            <img
              :src="`${staticUrl}/images/banner-tv-photo.png`"
              alt="Мы на ТВ!"
            />
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {goTo} from '~/helpers/baseFunction';
import {useRuntimeConfig} from "nuxt/app";

const runtimeConfig = useRuntimeConfig();
const staticUrl = runtimeConfig.public.vueAppStaticUrl;

function scrollToTariff() {
  const tariffElement = document.getElementById('tariff');
  if (tariffElement) goTo('#tariff');

}
</script>

<style scoped lang="scss">
.landing-banner {
  width: 100%;
  margin-top: 180px;

  .container {
    background-color: var(--var-blue-50);
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  &-info {
    width: 700px;
    padding: 32px;

    &__date {
      font-weight: 600;
      font-size: 22px;
      line-height: 29px;
      letter-spacing: 0.03em;
      color: var(--var-white-000);
      margin-bottom: 8px;
      opacity: 0.7;
    }

    &__title {
      font-weight: 700;
      font-size: 36px;
      line-height: 43px;
      letter-spacing: 0.03em;
      color: var(--var-white-000);
      margin-bottom: 32px;
    }

    &__description {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03em;
      max-width: 400px;
      color: var(--var-white-000);
      margin-bottom: 16px;
    }

    &__promo {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      margin-bottom: 48px;
      color: var(--var-white-000);
    }

    .landing-banner__btn {
      width: 263px;
      height: 56px;
      z-index: 100;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.02em;
    }
  }

  &-image-container {
    display: flex;
  }

  &-image {
    width: 716px;

    picture {
      position: relative;
      display: block;
      height: 100%;
      font-size: 0;
    }

    source,
    img {
      position: absolute;
      height: 142%;
      left: -50%;
      top: -21%;
      width: 210%;
      object-fit: cover;
      object-position: -115px -115px;
      clip-path: ellipse(27% 66% at 50% 50%);
    }
  }
}

@media (max-width: 1023px) {
  .landing-banner {
    &-image {
      img {
        height: 150%;
        top: -20%;
        width: 215%;
        clip-path: ellipse(27% 66% at 50% 47%);
      }
    }
  }
}

@media (max-width: 900px) {
  .landing-banner {
    &-image {
      img {
        object-position: -325px -115px;
      }
    }
  }
}

@media (max-width: 768px) {
  .landing-banner {
    .container {
      flex-direction: column;
    }

    &-info {
      padding: 32px 16px;
      width: 100%;

      &__description {
        max-width: 550px;
      }
    }

    &-info {
      .landing-banner__btn {
        width: 100%;
      }
    }

    &-image {
      height: 372px;

      img {
        top: 0;
        clip-path: ellipse(700px 260px at 45% 50%);
        -webkit-clip-path: ellipse(700px 260px at 45% 50%);
        object-position: -200px -115px;
      }

    }
  }
}
</style>

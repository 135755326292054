<template>
  <section class="landing-people">
    <div class="info-section section_3 container">
      <div class="info">
        <div class="title">
          {{ $t('forBegin') }}
        </div>
        <div class="title">
          {{ $t('forDesign') }}
        </div>
        <div class="title">
          {{ $t('forU') }}
        </div>
        <p class="text">
          {{ $t('gabBwBeginProf') }}
          <br />
          {{ $t('interiorAvailableEveryone') }}
        </p>
        <div class="goahead">
          <div class="goahead-item">
            <DownloadLauncherButton
              :add-version-to-label="false"
            />
          </div>
          <ui-link
            to="/help-center"
            class="goahead-btn q-ml-lg"
            style="padding: 0 20px 0 17px"
            @click="$emit('showVideo', 'promo')"
          >
            <IconPlay />
            {{ $t('howToWork') }}
          </ui-link>
        </div>
      </div>
      <div class="img__wrp">
        <img
          class="img__item-one"
          :src="`${staticUrl}/images/mp-people.webp`"
          width="484"
          height="316"
          alt="Dreamflat closes the gap between beginners and professionals."
          loading="lazy"
        />
      </div>
      <div class="mobile-download-buttons-container">
        <DownloadLauncherButton
          id="m-download"
          class="m-download"
          icon=""
          :add-version-to-label="false"
        />
        <ui-button
          class="landing-try-m-btn"
          color="primary"
          icon="play_circle_outline"
          :label="$t('howToUse')"
          no-caps
          :to="{path: 'help-center'}"
        >
          <template #before>
            <IconPlayCircle />
          </template>
        </ui-button>
      </div>
      <img
        :src="`${staticUrl}/images/mp-vector3.webp`"
        class="cards-bg item-r"
        loading="lazy"
        alt=""
      />
      <img
        :src="`${staticUrl}/images/mp-vector3.webp`"
        class="cards-bg item-l"
        loading="lazy"
        alt=""
      />
    </div>
  </section>
</template>

<script setup>
import DownloadLauncherButton from '~/components/DownloadLauncherButton.vue';
import IconPlay from '~/assets/svg/play.svg';
import IconPlayCircle from '~/assets/svg/play_circle.svg';
import {useRuntimeConfig} from '#imports';

const runtimeConfig = useRuntimeConfig();
const staticUrl = runtimeConfig.public.vueAppStaticUrl;
defineEmits(['showVideo']);
</script>

<style scoped lang="scss">
.goahead {
  display: flex;
  align-items: center;
  .goahead-btn {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $primary;
    cursor: pointer;
  }
}
.section_3 {
  //max-width: 1074px;
  //margin: 256px auto;
  position: relative;

  .info {
    margin-left: 0 !important;
    //padding-right: 25px;
    width: 500px;
  }

  .text {
    margin-top: 25px;
  }

  .title {
    margin-bottom: 0 !important;
  }

  .img__wrp {
    display: flex;
    flex-flow: wrap;
    //max-width: 600px;
    //width: 524px;
    margin-left: 24px;
    width: auto;
    height: auto;

    .img__item {
      margin: 11.5px;
      width: 150px;
      border-radius: 16px;
    }
    .img__item-one {
      width: 500px;
      height: auto;
      content-visibility: auto;
      aspect-ratio: auto 484 / 316;
    }
  }

  .cards-bg {
    position: absolute;
    width: 680px;
    height: 710px;
    background-size: contain;
    z-index: -2;
  }

  .item-r {
    left: 90%;
    top: -50%;
  }

  .item-l {
    right: 80%;
    bottom: -200%;
  }
}
.mobile-download-buttons-container {
  display: none;
}
@media (max-width: 1024px) {
  .cards-bg {
    display: none;
  }
  .item-r {
    display: none;
  }
  .item-l {
    display: none;
  }
  .mobile-download-buttons-container {
    display: flex;
    margin-top: 92px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    #m-download {
      display: flex;
      width: 100%;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 16px;
    }
    .how-to-work-mobile {
      width: 100%;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .q-page .d-btn {
    display: flex;
  }
}
@media (max-width: 600px) {
  .img__wrp {
    margin-left: 0 !important;
  }
}
</style>

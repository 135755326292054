<template>
  <div class="item">
    <div
      class="trigger"
      :class="{trigger_active: visible}"
      @click="open"
    >
      <span class="header">
        <slot name="trigger" />
      </span>
      <div class="arrow">
        <div v-if="!visible">+</div>
        <div v-else>-</div>
      </div>
    </div>
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-show="visible" class="content">
        <div class="content-inner">
          <slot name="content" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import {ref} from '#imports';

const visible = ref(false);

function open() {
  visible.value = !visible.value;
}

function start(el: HTMLDivElement) {
  el.style.height = el.scrollHeight + 'px';
}

function end(el: HTMLDivElement) {
  el.style.height = '';
}
</script>

<style lang="scss" scoped>
.item {
  border-radius: 12px;
  list-style-type: none;
  cursor: pointer;
}

.arrow {
  flex: 0 0 auto;
  width: 36px;
  height: 36px;
  background: $primary;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  & > * {
    transform: scale(1.5);
    margin-top: -3px;
  }
}

.trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  transition: 0.2s;
  color: #3d424a;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: 12px 12px 12px 24px;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>

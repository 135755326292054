<template>
  <div class="items-center bg-wrp main-wrp">
    <div class="bg"/>
    <div class="container">
      <LandingHero @show-video="startVideo($event)"/>
      <LandingTVBanner v-if="isDflat"/>
      <LandingSteps/>
      <LandingResults @show-video="startVideo($event)"/>
      <LandingTry/>
    </div>
    <LandingGallery/>
    <LandingPeople/>
    <LandingRates
      display="flex"
    />

    <section class="container container__mb">
      <UiTariffsInfo/>
    </section>
    <!--    <div class="m-btn">-->
    <!--      <p class="m-btn-text">-->
    <!--        {{ $t('Already available for Windows') }}-->
    <!--      </p>-->
    <!--      <ui-button-->
    <!--        color="primary"-->
    <!--        icon="play_circle_outline"-->
    <!--        :label="$t('How to work in the app')"-->
    <!--        :to="{ path: 'help' }"-->
    <!--      />-->
    <!--    </div>-->
    <LandingQuestions/>
    <VideoModal
      :show="showVideoModal"
      :video="video"
      @update:show="
        () => {
          showVideoModal = false;
        }
      "
    />
  </div>
</template>

<script lang="ts" setup>
import {ref} from 'vue';
import {useI18n} from 'vue-i18n';
import VideoModal from '~/components/VideoModal.vue';
import LandingHero from '~/modules/Landing/components/LandingHero.vue';
import LandingTVBanner from '~/modules/Landing/components/LandingTVBanner.vue';
import LandingSteps from '~/modules/Landing/components/LandingSteps.vue';
import LandingResults from '~/modules/Landing/components/LandingResults.vue';
import LandingTry from '~/modules/Landing/components/LandingTry.vue';
import LandingGallery from '~/modules/Landing/components/LandingGallery.vue';
import LandingPeople from '~/modules/Landing/components/LandingPeople.vue';
import LandingRates from '~/modules/Landing/components/LandingRates.vue';
import LandingQuestions from '~/modules/Landing/components/LandingQuestions.vue';
import {useRuntimeConfig} from '#imports';

const {t} = useI18n();

const homeTitle = t('Home');
const runtimeConfig = useRuntimeConfig();
const isDflat =
  runtimeConfig.public.vueAppBaseUrl.includes('d-flat.ru');

useHead({
  title: `${t(runtimeConfig.public.vueAppTitle)}${
    homeTitle ? ` - ${homeTitle}` : ''
  }`,
  meta: [
    {
      name: 'description',
      content: `${t('homeMetaDesc')}`,
    },
  ],
  charset: 'utf-8',
});
useSeoMeta({
  ogTitle: `${t(runtimeConfig.public.vueAppTitle)}${
    homeTitle ? ` - ${homeTitle}` : ''
  }`,
  description: `${t('homeMetaDesc')}`,
  ogDescription: `${t('homeMetaDesc')}`,
  ogImage: 'https://dreamflat.design/prev.webp',
  twitterCard: 'summary_large_image',
  ogUrl: 'https://dreamflat.design/',
  ogImageAlt: 'DreamFlat',
});

const showVideoModal = ref(false);
const video = ref('promo');

function startVideo(source: string) {
  video.value = source;
  showVideoModal.value = true;
}
</script>

<style lang="scss">
//.q-icon > svg {
//  width: 20px;
//  height: 20px;
//}
.container {
  max-width: 1128px;
}

.container__mb {
  margin-bottom: 160px;
}

.q-btn .q-icon,
.q-btn .q-spinner {
  font-size: 18px;
}

.q-btn.q-hoverable.bg-primary:hover {
  //background-color: #1679C8!important;
  background-color: #4ea6ec !important;
  transition: none !important;
}

//.q-btn.disabled{
//  background-color: #F4F6FD!important;
//  color: #E1E5F0;
//}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.info-section {
  display: flex;
  //justify-content: space-between;
  //margin-top: 276px;
  margin-top: 256px;
  //margin-bottom: 276px;
  margin-bottom: 256px;
  align-items: center;

  img {
    max-width: 484px;
  }

  .landing-try-image {
    width: 500px;
    margin-top: 16px;
  }

  .info {
    margin-left: 48px;

    .title {
      font-weight: 700;
      font-size: 36px;
      line-height: 120%;
      letter-spacing: 0.03em;
      margin-bottom: 24px;
      color: #3d424a;
    }

    .sub-title {
      width: 356px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #64686e;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 64px;
      letter-spacing: 0.02em;
      color: #64686e;
    }
  }

  // .try-info{
  //   margin-left: 27px;
  // }
}

.goahead-item {
  position: relative;
  display: inline-block;

  .goahead-item-after {
    //content: "attr(data-content)";
    position: absolute;
    text-align: center;
    top: -44px;
    width: 100%;
    left: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #839aaf;
  }
}

.section-no-margin {
  &.info-section {
    .info {
      margin-left: 0;
    }
  }

  .container {
    margin-top: 48px;
    width: 100%;

    .card {
      padding: 32px;
      background: #f6f8fd;
      border: 1px solid #e1e5f0;
      border-radius: 16px;
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;

      .card-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 535px;
        margin-right: 64px;

        .card-title {
          font-weight: 700;
          font-size: 36px;
          line-height: 120%;
          letter-spacing: 0.03em;
          color: $primary;
          margin-bottom: 16px;
        }

        .card-body {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: 0.03em;
          color: #3d424a;
          margin-bottom: 32px;
        }

        .card-footer {
          justify-self: flex-end;

          .q-btn {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            background: $primary;
            border-radius: 8px;
            filter: drop-shadow(
                0px 3px 10px rgba(36, 145, 231, 0.25)
            );
            box-shadow: none;
          }

          .watch-button {
            padding-left: 24px;
          }
        }
      }

      .card-image {
        width: 465px;
        position: relative;

        &.video {
          cursor: pointer;
        }

        img {
          border-radius: 12px;
          width: 100%;
        }
      }
    }
  }
}

.bg-wrp {
  position: relative;

  .bg {
    top: -3.2%;
    width: 100vw;
    height: 740px;
    background: no-repeat url($static-url + '/images/Vector214.webp');
    background-size: cover;
    position: absolute;
    z-index: -1;
  }
}

.m-btn {
  display: none;
}

.m-img {
  display: none;
}

.main-wrp {
  .q-btn {
    border-radius: 8px;
    height: 40px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.text-info {
  text-align: center;
  //margin-top: 300px;
  margin-top: 208px;

  .sub-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #64686e;
  }

  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: #3d424a;
    margin: 24px 0;

    span {
      color: #3e9eea;
      display: block;
    }
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #64686e;
  }
}

.cards {
  display: flex;
  justify-content: space-between;
  margin-top: 96px;
  position: relative;

  .item {
    width: 300px;
    min-height: 300px;
    display: flex;
    flex-direction: column;

    .item-btn {
      height: 36px;
      display: flex;
      align-items: center;
    }

    img {
      margin-bottom: 48px;
      max-width: 64px;
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.03em;
      color: #3d424a;
    }

    .text {
      flex-grow: 1;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.02em;
      color: #64686e;
      //margin-bottom: 43px;
      margin: 12px 0 32px 0;

      span {
        display: block;
        color: #b2c0cd;
        font-size: 12px;
        margin-top: 4px;
      }
    }

    .link {
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: $primary;
    }
  }

  .cards-bg {
    position: absolute;
    width: 680px;
    height: 710px;
    background-size: contain;
    z-index: -2;
  }

  .item-r {
    left: 90%;
    top: -50%;
  }

  .item-l {
    right: 80%;
    bottom: -200%;
  }
}

.m-btn {
  display: none;
}

@media (max-width: 1024px) {
  .main-wrp {
    .q-btn {
      height: 44px;
    }
  }
  // .d-btn {
  //   display: none;
  // }

  .m-btn {
    display: block !important;
  }

  .container {
    .head {
      flex-direction: column;

      .title {
        .goahead {
          display: none;
        }
      }

      .head-img-container {
        display: none;
      }
    }

    .price-card {
      .price {
        .price-name {
          font-size: 24px;
        }
      }

      .desc-tarif {
        position: relative;
      }
    }
  }

  .text-info {
    margin-top: 128px !important;
    padding-left: 5%;
    padding-right: 5%;
  }

  .cards {
    flex-direction: column;
    align-items: center;
    margin-top: 48px;

    .cards-bg {
      display: none;
    }

    .item-r {
      display: none;
    }

    .item-l {
      display: none;
    }

    .title {
      margin-bottom: 8px;
    }

    .item {
      text-align: center;
      align-items: center;
      min-height: auto !important;
      margin-right: 0 !important;
      margin-bottom: 61px;

      img {
        margin-bottom: 16px !important;
        width: 48px;
      }

      .title {
        font-size: 18px;
      }

      .text {
        //margin-bottom: 0 !important;
        margin-top: 0;
        font-size: 15px;

        span {
          font-size: 14px;
        }
      }
    }
  }
  .cards-head {
    flex-direction: column;
    margin-top: 0;
    //padding-left: 5%;
    justify-content: center;
    align-items: center;
    width: 100%;

    .item {
      max-width: 100% !important;
      width: 90%;
      padding: 12px 16px;
      background: #ffffff;
      border: 1px solid #f4f6fd;
      box-sizing: border-box;
      border-radius: 8px;
      margin-right: 0;

      .title {
        margin-top: 0;
        margin-bottom: 8px;
      }
    }
  }

  .m-btn {
    display: block;
    text-align: center;
    margin-top: 50px;

    .m-btn-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.02em;
      color: #7f97aa;
    }

    .q-btn {
      width: 80%;
    }
  }

  .info-section {
    flex-direction: column;
    margin: 81px auto 128px !important;
    //padding-left: 5%;
    //padding-right: 5%;

    img {
      display: none;
    }

    .card-image {
      img {
        display: block;
      }
    }

    .info {
      margin-left: 0 !important;
      width: 100% !important;

      .title {
        font-weight: 700;
        font-size: 24px !important;
        line-height: 32px !important;
      }

      .text {
        margin-top: 8px;
      }

      .m-btn {
        margin-top: 64px;
      }
    }

    .m-img {
      display: block;
      max-width: 80%;
      margin: 0 auto;
    }

    .goahead {
      display: none;
    }
  }

  .info-section__2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 5% !important;
    padding-right: 5% !important;

    .container {
      flex-direction: column;
    }

    .title {
      font-size: 24px !important;
      line-height: 32px !important;
    }

    .text {
      margin-bottom: 100px !important;
      max-width: 340px !important;
    }
  }

  .section_3 {
    margin: 128px auto 0 !important;
    flex-direction: column;

    img {
      display: block;
      //width: 115px !important; //Если картинки по отдельности
      width: 100% !important;
    }
  }

  .bg-wrp .bg {
    display: none;
  }

  .container--section-list {
    padding-left: 5%;
    padding-right: 5%;
  }

  .section-list {
    margin: 128px auto 128px !important;

    .title {
      font-size: 24px !important;
      line-height: 32px !important;
    }

    .list__wrp {
      margin-left: 0 !important;
      max-width: initial;
    }

    .list__item {
      width: 100% !important;
      margin: 0 auto;
    }

    .list__item-text {
      padding: 24px 12px;
    }
  }
}

@media (max-width: 600px) {
}
</style>

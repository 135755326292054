<template>
  <section class="landing-results">
    <div
      class="container info-section result-section flex-wrap section-no-margin"
    >
      <div class="info landing-results-info">
        <div class="title landing-results-info__title">
          {{ $t('finalResult') }}
        </div>
        <div class="sub-title landing-results-info__sub-title">
          {{ $t('examplesFinalResult') }}
        </div>
      </div>
      <div class="container results-card-container">
        <div class="card">
          <div class="card-main">
            <div class="main-container">
              <div class="card-title">
                {{ $t('renderers') }}
              </div>
              <div class="card-body">
                {{ $t('photorealisticRenderings') }}
              </div>
            </div>
            <div class="card-footer">
              <ui-button
                color="primary"
                :label="$t('openGallery')"
                :to="{path: 'gallery'}"
                style="width: 184px"
              />
            </div>
          </div>
          <div class="card-image video" @click="showFullImg = true">
            <img
              :src="`${staticUrl}/images/mp-render1.webp`"
              alt="Renderers"
              width="465"
              loading="lazy"
            />
          </div>
        </div>
        <div class="card">
          <div class="card-main">
            <div class="main-container">
              <div class="card-title">
                {{ $t('videoFlythroughs') }}
              </div>
              <div class="card-body">
                {{ $t('interiorVideos') }}
              </div>
            </div>

            <div class="card-footer">
              <ui-button
                class="watch-button"
                color="primary"
                :label="$t('watch')"
                @click="$emit('showVideo', 'video')"
              >
                <template #before>
                  <iconPlayCircle />
                </template>
              </ui-button>
            </div>
          </div>
          <div class="card-image video">
            <img
              :src="`${staticUrl}/images/mp-video-fly1.webp`"
              alt="Video fly-throughs"
              width="465"
              @click="$emit('showVideo', 'video')"
              loading="lazy"
            />
          </div>
        </div>
        <div class="card">
          <div class="card-main">
            <div class="main-container">
              <div class="card-title">
                {{ $t('tour3D') }}
              </div>
              <div class="card-body">
                {{ $t('createTourYourDesign') }}
              </div>
            </div>
            <div class="card-footer">
              <ui-button
                class="watch-button"
                color="primary"
                :label="$t('watch')"
                no-caps
                @click="
                  openInNewTab(
                    'https://dreamflat.design/tours/Tour_from_23.01.2023_12-06-11-496/index.htm?n=3',
                  )
                "
              >
                <template #before>
                  <iconPlayCircle />
                </template>
              </ui-button>
            </div>
          </div>
          <div class="card-image video">
            <img
              :src="`${staticUrl}/images/3d-tour1.webp`"
              alt="3D Tour"
              width="465"
              @click="
                openInNewTab(
                  'https://dreamflat.design/tours/Tour_from_23.01.2023_12-06-11-496/index.htm?n=3',
                )
              "
              loading="lazy"
            />
          </div>
        </div>
        <div class="card">
          <div class="card-main">
            <div class="main-container">
              <div class="card-title">
                {{ $t('vr') }}
              </div>
              <div class="card-body">
                {{ $t('unrealEngine5') }}
              </div>
            </div>
          </div>
          <div class="card-image">
            <img
              :src="`${staticUrl}/images/mp-vr.webp`"
              alt="VR"
              width="465"
              loading="lazy"
            />
          </div>
        </div>
        <div class="card">
          <div class="card-main">
            <div class="main-container">
              <div class="card-title">
                {{ $t('interactiveTour') }}
              </div>
              <div class="card-body">
                {{ $t('walkAroundApartment') }}
              </div>
            </div>
            <div class="card-footer">
              <ui-button
                class="watch-button"
                color="primary"
                :label="$t('watch')"
                no-caps
                @click="$emit('showVideo', 'interactive-tour')"
              >
                <template #before>
                  <iconPlayCircle />
                </template>
              </ui-button>
            </div>
          </div>
          <div class="card-image">
            <img
              :src="`${staticUrl}/images/mp-interactive1.webp`"
              alt="Interactive tour"
              width="465"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
    <ui-modal v-model="showFullImg">
      <img
        :src="`${staticUrl}/images/mp-render1.webp`"
        class="image-full"
        alt=""
        loading="lazy"
      />
    </ui-modal>
  </section>
</template>

<script setup lang="ts">
import IconPlayCircle from '~/assets/svg/play_circle.svg';
import UiButton from '~/components/Ui/UiButton.vue';
import {useRuntimeConfig} from '#imports';

const runtimeConfig = useRuntimeConfig();
const staticUrl = runtimeConfig.public.vueAppStaticUrl;

defineEmits(['showVideo']);
const showFullImg = ref(false);
function openInNewTab(url: string) {
  window.open(url, '_blank', 'noreferrer');
}
</script>
<style lang="scss">
.image-full {
  display: block;
  width: 100%;
  height: 100%;
}
.card-image {
  img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: $lg) {
  .card {
    padding: 16px;
  }
}
@media (max-width: 600px) {
  .info-section.result-section {
    margin: 81px auto 127px;
    padding: 0;
    .info {
      .title {
        padding: 0;
      }
    }
  }
  .landing-results-info {
    .landing-results-info__title {
      margin-bottom: 8px !important;
    }
    .landing-results-info__sub-title {
      font-size: 15px;
    }
  }
  .results-card-container {
    margin-top: 40px !important;
    padding: 0;
  }
  .card {
    padding: 16px;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 16px;
    .card-main {
      width: 100% !important;
      .main-container {
        .card-title {
          font-size: 24px !important;
          margin-bottom: 8px !important;
        }
        .card-body {
          font-size: 15px !important;
        }
      }
      .card-footer {
        a {
          width: 100% !important;
          //font-size: 16px!important;
        }
        button {
          width: 100% !important;
        }
      }
    }
    .card-image {
      width: 100% !important;
      img {
        border-radius: 6px !important;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
